import { LinkInterface } from '@/domain/interface/LinkInterface';
import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import { i18n } from '@/i18n';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';

const constants = new EnumConstantsMixin();

export class Schildeel {
  id: string;
  schildeelType?: EnumInterface;
  begrenzing?: EnumInterface;
  links: LinkInterface;

  constructor(responseObject?: Schildeel) {
    this.id = responseObject?.id || '';
    this.schildeelType = responseObject?.schildeelType;
    this.begrenzing = responseObject?.begrenzing;
    this.links = responseObject?.links || {};
  }

  isDak() {
    return (
      this.schildeelType?.code === constants.$code.schildeelType.PLAT_DAK ||
      this.schildeelType?.code === constants.$code.schildeelType.HELLEND_DAK ||
      this.schildeelType?.code === constants.$code.schildeelType.PLAFOND
    );
  }

  isVloer() {
    return this.schildeelType?.code === constants.$code.schildeelType.VLOER;
  }

  isGevel() {
    return this.schildeelType?.code === constants.$code.schildeelType.GEVEL;
  }

  isVensterOrLichteGevel() {
    return (
      this.schildeelType?.code ===
        constants.$code.schildeelType.VENSTER_PLAT_DAK ||
      this.schildeelType?.code ===
        constants.$code.schildeelType.VENSTER_HELLEND_DAK ||
      this.schildeelType?.code ===
        constants.$code.schildeelType.VENSTER_GEVEL ||
      this.schildeelType?.code === constants.$code.schildeelType.LICHTE_GEVEL
    );
  }

  isPoortOfDeur() {
    return (
      this.schildeelType?.code === constants.$code.schildeelType.POORT_OF_DEUR
    );
  }

  getTitle(): string {
    let title = '';
    if (this.schildeelType) {
      title += this.schildeelType.omschrijving;
      switch (this.schildeelType.code) {
        case constants.$code.schildeelType.VLOER:
        case constants.$code.schildeelType.GEVEL:
        case constants.$code.schildeelType.PLAFOND:
          if (this.begrenzing) {
            title +=
              ' ' +
              i18n.t('schildelen.tussenvoegsel') +
              ' ' +
              this.begrenzing.omschrijving.toLowerCase();
          }
          break;
        default:
          break;
      }
    }
    return title;
  }

  getDefaultName(): string {
    let name = '';
    if (this.schildeelType) {
      switch (this.schildeelType.code) {
        case constants.$code.schildeelType.VLOER:
          switch (this.begrenzing?.code) {
            case constants.$code.begrenzing.VOLLE_GROND:
              name = 'Vloer op volle grond';
              break;
            case constants.$code.begrenzing.KELDER:
              name = 'Vloer boven kelder';
              break;
            case constants.$code.begrenzing.AOR:
              name = 'Vloer boven AOR';
              break;
            case constants.$code.begrenzing.BUITEN:
              name = 'Vloer naar buiten';
              break;
          }
          break;
        case constants.$code.schildeelType.GEVEL:
          switch (this.begrenzing?.code) {
            case constants.$code.begrenzing.BUITEN:
              name = 'Buitengevel';
              break;
            case constants.$code.begrenzing.VOLLE_GROND:
              name = 'Gevel naar volle grond';
              break;
            case constants.$code.begrenzing.AOR_OF_KELDER:
              name = 'Gevel naar AOR of kelder';
              break;
          }
          break;
        default:
          name = this.schildeelType.omschrijving;
          break;
      }
    }
    return name;
  }
}
